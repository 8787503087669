import "bootstrap/dist/css/bootstrap.css";
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createGtm } from "vue-gtm";
import VueCookies from 'vue-cookies'
import "bootstrap/dist/js/bootstrap.js";
const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueCookies, {domain: 'buckedup.com'});
app.use(createGtm({id: "GTM-MV8VK25", enabled: true, vueRouter: router}));
app.mount('#app');