<template>
	<div class="">
		<router-view />
	</div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body,
html {
	background-color: #f0f2f9;
	height: 100%;
	color: #545967;
	font-family: 'Roboto', sans-serif;
}

#app {
	height: 100%;
}

nav {
	text-align: center;
	//padding: 1rem 0;
	background-color: #fff;
	position: fixed;
	z-index: 9999;
	top: 0;
	width: 100%;

	img {
		max-width: 15%;
	}
}

// .content-align {
// 	height: 100%;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	flex-direction: column;
// }

// .debug {
// 	background-color: black;
// 	border: 5px dashed red;
// 	color: white;
// 	margin: 10px;
// 	padding: 10px;
// }
</style>
