<template>
  <nav>
    <img :src="require('@/assets/bk-logo-navbar.webp')" alt="">
  </nav>

  <div class="homeview row" v-if="loading">
    <div class="col-md-4" v-if="variants && show0 && variants[0] && variants[0].options">
      <h6 class="m-3 mb-3 title">Pick your {{ variants[0].name }} options</h6>
      <div class="card">
        <div class="row">
          <div class="title">
            <p>
              {{ variants[0].options[0].name }}:
              {{ variants[0].options[0].values[selected0].name }}
            </p>
          </div>
          <div class="col-6 mb-5" v-if="variants[0]">
            <img v-if="variants[0].options[0].values[selected0].images[0]"
              :src="variants[0].options[0].values[selected0].images[0]" class="img-fluid" />
            <img v-else :src="variants[0].image" class="img-fluid" />
          </div>
          <div class="col-6 mb-5" v-if="variants[0]">
            <div class="grid" v-if="variants[0].options[1] && variants[0].options[1].name.includes('Size')">
              <div v-for="(item, index) in variants[0].options[0].values" :key="index" @click="selected0 = index"
                :class="index == selected0 ? 'selected' : ''">
                <!--<div :class="'disabled'" v-if="!item.in_stock"></div>-->
                <img :src="item.images[0]" v-if="item.images" />
                {{ item[0] }}
              </div>
            </div>
            <div class="grid" v-else>
              <div v-for="(item, index) in variants[0].options[0].values" :key="index"
                @click="item.in_stock ? selected0 = index : ''" :class="index == selected0 ? 'selected' : ''">
                <div :class="'disabled'" v-if="!item.in_stock"></div>
                <img :src="item.images[0]" v-if="item.images" />
                {{ item[0] }}
              </div>
            </div>
          </div>

          <div class="title" v-if="variants[0].options && variants[0].options[1]">
            <p>
              {{ variants[0].options[1].name }}:
              {{ variants[0].options[1].values[selected0_1].name }}
            </p>
          </div>
          <div class="col-6" v-if="variants[0].options && variants[0].options[1]">
            <img v-if="variants[0].options[1].values[selected0_1].images[0]"
              :src="variants[0].options[1].values[selected0_1].images[0]" class="img-fluid" />
            <img v-else :src="variants[0].image" class="img-fluid" />
          </div>
          
          <div class="col-6" v-if="variants[0].options[1] && !variants[0].options[1].name.includes('Size')">
            <div class="grid">
              <div v-for="(item, index) in variants[0].options[1].values" :key="index"
                @click="item.in_stock ? selected0_1 = index : ''" :class="index == selected0_1 ? 'selected' : ''">
                <div :class="'disabled'" v-if="!item.in_stock"></div>
                <img :src="item.images[0]" v-if="item.images" />
                {{ item[0] }}
              </div>
            </div>
          </div>
          <div class="col-6" v-if="variants[0].options[1] && variants[0].options[1].name.includes('Size')">
            <p>
              {{ variants[0].options[1].name }}:
              {{ variants[0].options[1].values[selected0_1].name }}
            </p>
            <div class="grid" v-if="variants[0].options">
              <div v-for="(item, index) in possiveis" :key="index" @click="item.in_stock ? selected0_1 = index : ''"
                :class="index == selected0_1 ? 'selected' : ''">
                <div :class="'disabled'" v-if="!item.in_stock"></div>
                {{ tratar(item.name) }}
                {{ item[0] }}
              </div>
            </div>
          </div>


           


        </div>
      </div>
    </div>

    <div class="col-md-4" v-if="variants && show1 && variants[1] && variants[1].options">
      <h6 class="m-3 mb-3 title">Pick your {{ variants[1].name }} options</h6>
      <div class="card">
        <div class="row">
          <div class="title">
            <p>
              {{ variants[1].options[0].name }}:
              {{ variants[1].options[0].values[selected1].name }}
            </p>
          </div>
          <div class="col-6 mb-5" v-if="variants[1]">
            <img v-if="variants[1].options[0].values[selected1].images[0]"
              :src="variants[1].options[0].values[selected1].images[0]" class="img-fluid" />
            <img v-else :src="variants[1].image" class="img-fluid" />
          </div>
          <div class="col-6 mb-5" v-if="variants[1]">
            <div class="grid" v-if="variants[1].options[1] && variants[1].options[1].name == 'Size'">
              <div v-for="(item, index) in variants[1].options[0].values" :key="index" @click="selected1 = index"
                :class="index == selected1 ? 'selected' : ''">
                <!--<div :class="'disabled'" v-if="!item.in_stock"></div>-->
                <img :src="item.images[0]" v-if="item.images" />
                {{ item[0] }}
              </div>
            </div>
            <div class="grid" v-else>
              <div v-for="(item, index) in variants[1].options[0].values" :key="index"
                @click="item.in_stock ? selected1 = index : ''" :class="index == selected1 ? 'selected' : ''">
                <div :class="'disabled'" v-if="!item.in_stock"></div>
                <img :src="item.images[0]" v-if="item.images" />
                {{ item[0] }}
              </div>
            </div>
          </div>
          <div class="col-6" v-if="variants[1].options && variants[1].options[1]">
            <img v-if="variants[1].options[1].values[selected1_1].images[0]"
              :src="variants[1].options[1].values[selected1_1].images[0]" class="img-fluid" />
            <img v-else :src="variants[1].image" class="img-fluid" />
          </div>
          <div class="col-6" v-if="variants[1].options[1] && !variants[1].options[1].name.includes('Size')">
            <div class="grid">
              <div v-for="(item, index) in variants[1].options[1].values" :key="index"
                @click="item.in_stock ? selected1_1 = index : ''" :class="index == selected1_1 ? 'selected' : ''">
                <div :class="'disabled'" v-if="!item.in_stock"></div>
                <img :src="item.images[0]" v-if="item.images" />
                {{ item[0] }}
              </div>
            </div>
          </div>
          <div class="col-6" v-if="variants[1].options[1] && variants[1].options[1].name.includes('Size')">
            <p>
              {{ variants[1].options[1].name }}:
              {{ variants[1].options[1].values[selected1_1].name }}
            </p>
            <div class="grid" v-if="variants[1].options">
              <div v-for="(item, index) in possiveis1" :key="index" @click="item.in_stock ? selected1_1 = index : ''"
                :class="index == selected1_1 ? 'selected' : ''">
                <div :class="'disabled'" v-if="!item.in_stock"></div>
                {{ tratar(item.name) }}
                {{ item[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4" v-if="variants && show2 && variants[2] && variants[2].options">

      <h6 class="m-3 mb-3 title">Pick your {{ variants[2].name }} options</h6>
      <div class="card">
        <div class="row">
          <div class="title">
            <p>
              {{ variants[2].options[0].name }}:
              {{ variants[2].options[0].values[selected2].name }}
            </p>
          </div>
          <div class="col-6 mb-5" v-if="variants[2]">
            <img v-if="variants[2].options[0].values[selected2].images[0]"
              :src="variants[2].options[0].values[selected2].images[0]" class="img-fluid" />
            <img v-else :src="variants[2].image" class="img-fluid" />
          </div>
          <div class="col-6 mb-5" v-if="variants[2]">
            <div class="grid" v-if="variants[2].options[1] && variants[2].options[1].name == 'Size'">
              <div v-for="(item, index) in variants[2].options[0].values" :key="index" @click="selected2 = index"
                :class="index == selected2 ? 'selected' : ''">
                <!--<div :class="'disabled'" v-if="!item.in_stock"></div>-->
                <img :src="item.images[0]" v-if="item.images" />
                {{ item[0] }}
              </div>
            </div>
            <div class="grid" v-else>
              <div v-for="(item, index) in variants[2].options[0].values" :key="index"
                @click="item.in_stock ? selected2 = index : ''" :class="index == selected2 ? 'selected' : ''">
                <div :class="'disabled'" v-if="!item.in_stock"></div>
                <img :src="item.images[0]" v-if="item.images" />
                {{ item[0] }}
              </div>
            </div>
          </div>
          <div class="col-6" v-if="variants[2].options && variants[2].options[1]">
            <img v-if="variants[2].options[1].values[selected2_1].images[0]"
              :src="variants[2].options[1].values[selected2_1].images[0]" class="img-fluid" />
            <img v-else :src="variants[2].image" class="img-fluid" />
          </div>
          <div class="col-6" v-if="variants[2].options[1] && !variants[2].options[1].name.includes('Size')">
            <div class="grid">
              <div v-for="(item, index) in variants[2].options[1].values" :key="index"
                @click="item.in_stock ? selected2_1 = index : ''" :class="index == selected2_1 ? 'selected' : ''">
                <div :class="'disabled'" v-if="!item.in_stock"></div>
                <img :src="item.images[0]" v-if="item.images" />
                {{ item[0] }}
              </div>
            </div>
          </div>
          <div class="col-6" v-if="variants[2].options[1] && variants[2].options[1].name.includes('Size')">
            <p>
              {{ variants[2].options[1].name }}:
              {{ variants[2].options[1].values[selected2_1].name }}
            </p>
            <div class="grid"  v-if="variants[2].options">
              
               <div v-for="(item, index) in possiveis2" :key="index" @click="item.in_stock ? selected2_1 = index : ''"
                :class="index == selected2_1 ? 'selected' : ''">
                <div :class="'disabled'" v-if="!item.in_stock"></div>
                {{ tratar(item.name) }}
                {{ item[0] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="text-center">
    <button type="button" @click="checkout" class="btn btn-claim">
      CLAIM YOURS!
    </button>
  </div>
</template>

<script>
import axios from "axios";
const { entries, map } = require('lodash')
// @ is an alias to /src
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      variants: [],
      product0: null,
      variant0: null,
      variant0ID: null,
      product1: null,
      variant1: null,
      variant1ID: null,
      product2: null,
      variant2: null,
      variant2ID: null,
      product3: null,
      variant3: null,
      variant3ID: null,
      product4: null,
      variant4: null,
      variant4ID: null,
      show0: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      selected0: 0,
      selected1: 0,
      selected2: 0,
      selected3: 0,
      selected4: 0,
      selected0_1: 0,
      selected1_1: 0,
      selected2_1: 0,
      cupom: '',
      regraEstoque: false,
      subid_1: '',
      loading: false,
      first_page: '',
      version: 'modal',
      country: null
    };
  },
  methods: {
    async getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          console.log("Log",c.substring(name.length, c.length))
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    selectProduct(productArray, key) {
      this[productArray].forEach((product) => {
        product.selected = false;
      });
      this[productArray][key].selected = true;
    },
    
    checkStok(){
      console.log(this.variants[0].options[1].values.length)
      for(var a = (this.variants[0].options[1].values.length-1); a > -1; a--)
      {
        if(this.variants[0].options[1].values[a].in_stock)
        {
          this.selected0_1 = a
        }
      }
    },
     checkStok1(){
      console.log(this.variants[1].options[1].values.length)
      for(var a = (this.variants[1].options[1].values.length-1); a > -1; a--)
      {
        if(this.variants[1].options[1].values[a].in_stock)
        {
          this.selected1_1 = a
        }
      }
    },
     checkStok2(){
      console.log(this.variants[2].options[1].values.length)
      for(var a = (this.variants[2].options[1].values.length-1); a > -1; a--)
      {
        if(this.variants[2].options[1].values[a].in_stock)
        {
          this.selected2_1 = a
        }
      }
    },
    async fetchVariantsList(id, key) {
      this.loading = true;
      try {
        let url = `https://ar5vgv5qw5.execute-api.us-east-1.amazonaws.com/list/${id}`
        //https://h03ygoadc1      //ar5vgv5qw5
        if(this.country)
          url = url + "?country=" + this.country
        let { data } = await axios.get(
          url
        );
        if (data.product.options.length > 0)
          this.variants[key] = data.product;
        else
          this.variants[key] = { options: null }; //[{ values: [] }]

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    tratar(palavra) {
      return palavra.split(' ')[0]
    },
    async checkout() {
      this.loading = !this.loading;
      try {
        var body = `cc=${this.cupom}`;
        if (this.product0 && this.variants[0].options) {
          if (this.show0) {
            body = body + `&products[0][id]=${this.product0}&products[0][quantity]=1&products[0][options][${this.variants[0].options[0].id}]=${this.variants[0].options[0].values[this.selected0].id}`
            if (this.variants[0].options[1]) {
              body = body + `&products[0][options][${this.variants[0].options[1].id}]=${this.variants[0].options[1].values[this.selected0_1].id}`
            }
          } else {
            body = body + `&products[0][id]=${this.product0}&products[0][quantity]=1&products[0][options][${this.variant0ID}]=${this.variant0}`
          }
        }
        else{
          body = body + `&products[0][id]=${this.product0}`
        }
        if (this.product1) {
          if(this.variants[1].options){
          if (this.show1) {
            body = body + `&products[1][id]=${this.product1}&products[1][quantity]=1&products[1][options][${this.variants[1].options[0].id}]=${this.variants[1].options[0].values[this.selected1].id}`
            if (this.variants[1].options[1]) {
              body = body + `&products[1][options][${this.variants[1].options[1].id}]=${this.variants[1].options[1].values[this.selected1_1].id}`
            }
          } else {
            body = body + `&products[1][id]=${this.product1}&products[1][quantity]=1&products[1][options][${this.variant1ID}]=${this.variant0}`
          }
        }
        else{
          body = body + `&products[1][id]=${this.product1}`
        }
        }
        if (this.product2) {
          if(this.variants[2].options){
          if (this.show2) {
            body = body + `&products[2][id]=${this.product2}&products[2][quantity]=1&products[2][options][${this.variants[2].options[0].id}]=${this.variants[2].options[0].values[this.selected2].id}`
            if (this.variants[2].options[1]) {
              body = body + `&products[2][options][${this.variants[2].options[1].id}]=${this.variants[2].options[1].values[this.selected2_1].id}`
            }
          } else {
            body = body + `&products[2][id]=${this.product2}&products[2][quantity]=1&products[2][options][${this.variant2ID}]=${this.variant0}`
          }
        }
        else{
          body = body + `&products[2][id]=${this.product2}`
        }
        }
        if (this.product3) {
          if(this.variants[3].options){
          if (this.show3) {
            body = body + `&products[3][id]=${this.product3}&products[3][quantity]=1&products[3][options][${this.variants[3].options[0].id}]=${this.variants[3].options[0].values[this.selected3].id}`
            if (this.variants[3].options[1]) {
              body = body + `&products[3][options][${this.variants[3].options[1].id}]=${this.variants[3].options[1].values[this.selected3_1].id}`
            }
          } else {
            body = body + `&products[3][id]=${this.product3}&products[3][quantity]=1&products[3][options][${this.variant3ID}]=${this.variant0}`
          }
        }
        else{
          body = body + `&products[3][id]=${this.product3}`
        }
        }

        if (this.subid_1 && this.subid_1 != 'null')
          body = body + (`${this.subid_1}`).replace("?", "&")

        var price = 0
        try {
          if (this.variants[0].options[0].values[this.selected0].price && this.variants[0].options[0].values[this.selected0_1].price) {
            price = parseFloat(this.variants[0].price.match(/\d+\.\d+/)[0]); + parseFloat((this.variants[0].options[0].values[this.selected0].price).replace("$", "")) + parseFloat((this.variants[0].options[0].values[this.selected0_1].price.match(/\d+\.\d+/)[0]))
          }
        }
        catch (err) {
          if(this.variants[0].options)
            price = this.variants[0].options[0].values[this.selected0].price
        }
        console.log("price", price)

        
        window.dataLayer.push({
            step_count: "modal", //modal
            page_id: this.first_page.replace("/", "").replace("/", ""), //{{page_id, página anterior}} 
            version_id: this.version, //v2-modal
            event: 'interaction', //offer_view, interaction, pageview
            action: 'purchase', //purchase, purchase-us,click, view_page
            value: price, //final purchase value
            transaction_id: 0,
                  country: this.country ? this.country : "us",
            currency: this.country
            ? this.variants[0].price.substr(
                0,
                3
              )
            : "USD"
          })


        
        /*document.cookie = "offer_id="+this.first_page+"; path=/; domain=https://buckedup.com";
        this.$cookies.set('offer_id', this.first_page);
        */
       //console.log(`https://buckedup.com/cart/add?${body}`)
        if(this.country)
          window.location.href = `https://${this.country}.buckedup.com/cart/add?${body}&clear=true`;
        else
          window.location.href = `https://buckedup.com/cart/add?${body}&clear=true`;

        this.loading = !this.loading;
      } catch (error) {
        this.loading = !this.loading;
        console.log(error);
      }
    },
  },
  computed: {
    possiveis() {
      let possiveis = this.variants[0].options[1].values.filter((item) => {
        const result = map(entries(this.variants[0].stock), ([ key, value ]) => {
            const array = JSON.parse(key)
            if(value>0)
            return { options: array[0] +"_"+ array[1] +"_"+ value }
        })
       if(JSON.stringify(result).includes(this.variants[0].options[0].values[this.selected0].id +"_"+item.id) || JSON.stringify(result).includes(item.id+"_"+this.variants[0].options[0].values[this.selected0].id ))
            {item.in_stock = true
            return item}
       else{
           item.in_stock = false
           return item
       }
      })
      this.checkStok()
      return possiveis
    },
    possiveis1() {
      let possiveis1 = this.variants[1].options[1].values.filter((item) => {
        const result = map(entries(this.variants[1].stock), ([ key, value ]) => {
            const array = JSON.parse(key)
            if(value>0)
            return { options: array[0] +"_"+ array[1] +"_"+ value }
        })
       if(JSON.stringify(result).includes(this.variants[1].options[0].values[this.selected1].id +"_"+item.id) || JSON.stringify(result).includes(item.id+"_"+this.variants[1].options[0].values[this.selected1].id ))
            {item.in_stock = true
            return item}
       else{
           item.in_stock = false
           return item
       }
      })
      this.checkStok1()
      return possiveis1
    },
    possiveis2() {
      try {
        let possiveis2 = this.variants[2].options[1].values.filter((item) => {
          const result = map(entries(this.variants[2].stock), ([ key, value ]) => {
            const array = JSON.parse(key)
            if(value>0)
            return { options: array[0] +"_"+ array[1] +"_"+ value }
        })
       if(JSON.stringify(result).includes(this.variants[2].options[0].values[this.selected2].id +"_"+item.id) || JSON.stringify(result).includes(item.id+"_"+this.variants[2].options[0].values[this.selected2].id ))
            {item.in_stock = true
            return item}
       else{
           item.in_stock = false
           return item
       }
      })
      this.checkStok2()
      return possiveis2
      }
      catch (err) {
        return this.variants[2].options[1]
      }
    }
  },
  async mounted() {
    const params = new URLSearchParams(window.location.search);
    const queryString = window.location.search;
    this.subid_1 = queryString;
    this.first_page = params.get("first_page");
    if(params.get("country"))
      this.country = params.get("country");
    if(await this.getCookie('offer_id')){
      this.version = await this.getCookie('offer_id')
    }

    var _this = this
    if (params.has("product0")) {
      this.product0 = params.get("product0");
      console.log(this.subid_1)
      this.subid_1 = this.subid_1.replace(`product0=${this.product0}`, "")
      console.log(this.subid_1)
      this.variant0 = params.get("variant0");
      this.variant0ID = params.get("variant0ID");

      window.dataLayer.push({
            step_count: "modal", //modal
            page_id: this.first_page.replace("/", "").replace("/", "") , //{{page_id, página anterior}} 
            version_id: this.version, //v2-modal
            event: 'offerview', //offer_view, interaction, pageview
            action: 'offerview', //purchase, purchase-us,click, view_page
            transaction_id: 0,
                  country: this.country ? this.country : "us",
          })
          
      if (this.variant0ID === "undefined" || !params.has("variant0ID") || this.variant0ID === "0" || this.variant0ID === "n") {
        this.show0 = true;
      }
      await this.fetchVariantsList(this.product0, 0);
      
        if(this.variants[0].options){
      if (Object.keys(this.variants[0].stock)[0].includes(",")) {
        this.regraEstoque = true
      }
      else {
        if (this.variants[0].options[0] && !this.variants[0].options[0].values[0].in_stock) {
          _this.selected0 = _this.selected0 + 1
          for (var a = 1; a < this.variants[0].options[0].values.length; a++) {
            if (!this.variants[0].options[0].values[a].in_stock) {
              _this.selected0 = _this.selected0 + 1
            }
            else
              a = 99
          }
        }
        if (this.variants[0].options[1] && !this.variants[0].options[1].values[0].in_stock) {
          _this.selected0_1 = _this.selected0_1 + 1
          for (a = 1; a < this.variants[0].options[1].values.length; a++) {
            if (!this.variants[0].options[1].values[a].in_stock) {
              _this.selected0_1 = _this.selected0_1 + 1
            }
            else
              a = 99
          }
        }
      }
      
        }
    }
    if (params.has("product1")) {
      this.product1 = params.get("product1");
      this.subid_1 = this.subid_1.replace(`product1=${this.product1}`, "")
      this.variant1 = params.get("variant1");
      this.variant1ID = params.get("variant1ID");
      if (this.variant1ID === "undefined" || !params.has("variant1ID") || this.variant1ID === "0" || this.variant1ID === "n") {
        this.show1 = true;
      }
      await this.fetchVariantsList(this.product1, 1);
    }
    if (params.has("product2")) {
      this.product2 = params.get("product2");
      this.subid_1 = this.subid_1.replace(`product2=${this.product2}`, "")
      this.variant2 = params.get("variant2");
      this.variant2ID = params.get("variant2ID");
      if (this.variant2ID === "undefined" || !params.has("variant2ID") || this.variant2ID === "0" || this.variant2ID === "n") {
        this.show2 = true;
      }
      await this.fetchVariantsList(this.product2, 2);
    }
    if (params.has("product3")) {
      this.product3 = params.get("product3");
      this.subid_1 = this.subid_1.replace(`product3=${this.product3}`, "")
      this.variant3 = params.get("variant3");
      this.variant3ID = params.get("variant3ID");
      if (this.variant3ID === "undefined" || !params.has("variant3ID")) {
        this.show3 = true;
      }
      await this.fetchVariantsList(this.product3, 3);
    }
    if (params.has("product4")) {
      this.product4 = params.get("product4");
      this.variant4 = params.get("variant4");
      this.variant4ID = params.get("variant4ID");
      if (this.variant4ID === "undefined" || !params.has("variant4ID")) {
        this.show4 = true;
      }
      await this.fetchVariantsList(this.product4, 4);
    }
    if (params.has("discount")) {
      this.cupom = params.get("discount");
    }

    console.log("var", this.variants[2])
    this.loading = false
    this.loading = true

    setTimeout(() => {
      if (!this.show4 && !this.show0 && !this.show1 && !this.show2 && !this.show3) {
        this.checkout()
      }
    }, "2 second")

  },
};
</script>

<style lang="scss">
.card {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  margin-top: 16px;
  padding: 1.5rem 1.5rem;
  border-color: #fff;
  min-height: 40vh;
  //justify-content: center;
}

.grid {
  display: flex;
  // max-width: 200px;
  flex-wrap: wrap;
  margin-top: 15px;

  div {
    border: 1px solid transparent;
    border-radius: 50%;
    padding: 5px;
    margin: 0.5rem 0.5rem 0 0;
    position: relative;
    cursor: pointer;

    &.selected {
      border-color: #01ff85;

      &:before {
        content: "✓";
        border-radius: 50%;
        text-align: center;
        line-height: 1.5;
        font-size: 0.8rem;
        width: 20px;
        height: 20px;
        font-weight: bold;
        position: absolute;
        background: #01ff85;
        color: #000;
        top: -8%;
        left: -8%;
      }
    }

    img {
      max-width: 35px;
      border-radius: 50%;
    }
  }
}

.title {
  text-align-last: center;
}

.disabled {
  background-color: dimgrey;
  color: linen;
  opacity: 0.8;
  width: 100%;
  position: absolute !important;
  top: -8px;
  left: 0px;
  z-index: 3;
  height: 47px;
}


.btn-claim {
  margin-top: 2rem;
  padding: 1.25rem 4.5rem;
  background: #25bfff;
  border-radius: 100px;
  color: #28125d;
  font-weight: bold;
  margin-bottom: 15px;
}

h6 {
  font-weight: bolder;
  color: #28125d;
}

.homeview {
  display: flex;
  justify-content: center;
  margin-top: 60px;

  @media (max-width: 426px) {
    margin-top: 20px;
  }

}
</style>
